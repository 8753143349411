import { useReactiveVar } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';
import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import { IconColor, IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import Icons from '@aurora/shared-client/icons';
import type { MessagePagesAndParams } from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import type {
  Message,
  MessageEdge,
  ReplyMessage
} from '@aurora/shared-generated/types/graphql-schema-types';
import { ConversationStyle } from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import UrlHelper from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlHelper';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import ConversationStyleBehaviorHelper from '../../../helpers/boards/ConversationStyleBehaviorHelper';
import placeholderMessage from '../../../helpers/placeholders/placeholderMessage';
import type { ItemType, MessageViewVariant } from '../../../types/enums';
import type {
  ContextMessageFragment,
  ContextMessageQuery,
  ContextMessageQueryVariables
} from '../../../types/graphql-types';
import messageQuery from '../../context/ContextMessage.query.graphql';
import { UrlObject, useContextObjectRefFromUrl } from '../../context/useContextObjectFromUrl';
import type { ItemViewTypeAndProps } from '../../entities/types';
import useTranslation from '../../useTranslation';
import MessageView from '../MessageView/MessageView';
import localStyles from './MessageDeepLink.module.css';
import { deepLinkMessageVar, updatedMessageForDeepLinkVar } from './MessageReactVarHelper';

interface Props {
  /**
   * The root message - topic message or article.
   */
  rootMessage: ContextMessageFragment;
  /**
   * Message view variant type and props.
   */
  viewVariant: ItemViewTypeAndProps<ItemType.MESSAGE, MessageViewVariant>;
}

/**
 * Renders a deeply-linked message along with it's immediate parents. The query to get the parents will be executed
 * as part of rendering this component based on the reply/comment ID present in the URL.
 *
 * @author Vaibhav Chawla
 *
 * @constructor
 */
const MessageDeepLink: React.FC<React.PropsWithChildren<Props>> = ({
  rootMessage,
  viewVariant
}) => {
  const cx = useClassNameMapper(localStyles);
  const tenant = useContext(TenantContext);
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_DEEP_LINK
  );
  const { router } = useEndUserRoutes();
  const { id: deepLinkedMessageId } = useContextObjectRefFromUrl(UrlObject.REPLY);
  const updatedMessageForDeepLink = useReactiveVar(updatedMessageForDeepLinkVar);
  const deepLinkMessageInStore = deepLinkMessageVar();
  const newRepliesAdded = useRef<number>(0);

  if (deepLinkMessageInStore != null && deepLinkedMessageId != deepLinkMessageInStore.id) {
    deepLinkMessageVar(null);
  }

  const queryResult = useQueryWithTracing<ContextMessageQuery, ContextMessageQueryVariables>(
    module,
    messageQuery,
    {
      variables: {
        id: deepLinkedMessageId,
        useParentTree: true,
        useMessageRepliesStatus: true
      },
      skip: deepLinkedMessageId === null || deepLinkMessageInStore != null,
      onCompleted: deepLink => {
        deepLinkMessageVar(cloneDeep(deepLink.message) as Message);
      }
    }
  );

  const { loading, fetchMore } = queryResult;
  let { data } = queryResult;
  let oldDeepLinkedMessage: Message;

  /**
   * Recursive Helper function to traverse the given message's replies if present.
   *
   * @param message the deep linked message.
   * @param idToCheck the id of the message to find in the deep linked message.
   */
  function traverseReplies(message: Message, idToCheck: string): Message {
    let foundMessage = null;
    if (message.id == idToCheck) {
      return message;
    }
    message.replies?.edges.forEach(mess => {
      foundMessage = traverseReplies((mess as MessageEdge).node, idToCheck);
    });
    return foundMessage;
  }

  /**
   * Helper function to traverse the Deep Link messages parent hierarchy and returns the message if present.
   *
   * @param idToCheck the id of the message to find in the deep link message.
   */
  function findMessageInDeepLinkParents(idToCheck: string) {
    let clonedDeepLinkedMessage = oldDeepLinkedMessage;
    let parentMessage = null;
    let foundParent = false;

    while (!foundParent && clonedDeepLinkedMessage) {
      parentMessage = traverseReplies(clonedDeepLinkedMessage, idToCheck);
      if (parentMessage) {
        foundParent = true;
        break;
      }
      clonedDeepLinkedMessage = (clonedDeepLinkedMessage as ReplyMessage).parent as Message;
    }
    return parentMessage;
  }

  /**
   * Helper function to handle new reply in perma link page.
   * When a new reply is created, on success callback in MessageViewStandard we get the new message
   * that's get stored in global react var - updatedMessageForDeepLinkVar
   * We read the value here and update the current deep link hierarchy by finding the parent message
   * and add new replies to the parent.
   *
   */
  function handleNewReplyInDeepLink() {
    const updatedMessage = updatedMessageForDeepLink;
    const parentMessage = findMessageInDeepLinkParents(
      (updatedMessage as ReplyMessage)?.parent?.id
    );
    const newEdge: MessageEdge = {
      node: placeholderMessage(
        tenant,
        {
          ...(updatedMessage as Message)
        },
        true
      ),
      cursor: null
    };
    newRepliesAdded.current = newRepliesAdded.current + 1;
    if (parentMessage) {
      if (parentMessage.replies) {
        parentMessage['replies'] = {
          edges: [newEdge, ...parentMessage.replies.edges],
          __typename: 'MessageConnection'
        };
      } else {
        parentMessage['replies'] = {
          edges: [newEdge],
          __typename: 'MessageConnection'
        };
      }
    }
  }

  /**
   * Helper function to handle updating a reply in perma-link page.
   * When a new reply is updated, on success callback in MessageViewStandard we get the updated message
   * that's get stored in global react var - updatedMessageForDeepLinkVar
   * We read the value here and update the current deep link hierarchy by finding the message.
   */
  function handleUpdateReplyInDeepLink() {
    const updatedMessage = updatedMessageForDeepLink;
    const foundMessage = findMessageInDeepLinkParents(updatedMessage.id);
    if (foundMessage) {
      Object.keys(updatedMessage).forEach(key => {
        if (key != 'author') {
          foundMessage[key] = updatedMessage[key];
        }
      });
    }
    return foundMessage;
  }

  if (updatedMessageForDeepLink && deepLinkMessageInStore) {
    oldDeepLinkedMessage = deepLinkMessageInStore;
    if (!handleUpdateReplyInDeepLink()) {
      handleNewReplyInDeepLink();
    }
  } else if (deepLinkMessageInStore != null) {
    oldDeepLinkedMessage = deepLinkMessageInStore;
  }

  if (oldDeepLinkedMessage != null) {
    data = { message: oldDeepLinkedMessage };
  }

  const [deepLinkedMessage, setDeepLinkedMessage] = useState<Message>(
    (data?.message as Message) ?? oldDeepLinkedMessage
  );
  const [showParentMessagesAction, setShowParentMessagesAction] = useState<boolean>(true);
  const lastParent = useRef(data?.message);
  const deepLinkedMessageRef = useRef(null);
  const showParentMessagesUsed = useRef(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (lastParent.current?.depth === 1) {
      setShowParentMessagesAction(false);
    }
    const deepLinkedMessageElement = deepLinkedMessageRef.current as HTMLElement;
    if (!showParentMessagesUsed.current && deepLinkedMessageElement) {
      deepLinkedMessageElement.scrollIntoView({
        behavior: 'smooth'
      });
    }
  });

  useEffect(() => {
    setDeepLinkedMessage(data?.message as Message);
  }, [data?.message]);

  if (textLoading || loading) {
    return null;
  }

  const { message } = data;

  async function viewFullDiscussion(): Promise<void> {
    const { messagePage } = ConversationStyleBehaviorHelper.getInstance(message?.board);

    await router.pushRoute<MessagePagesAndParams>(messagePage, {
      boardId: rootMessage?.board.displayId,
      messageSubject: UrlHelper.determineSlugForMessagePath(rootMessage),
      messageId: rootMessage?.uid.toString()
    });
  }

  function renderMessage(entity, decoratedMessageViewVariant) {
    lastParent.current = entity;
    return <MessageView entity={entity} variant={decoratedMessageViewVariant} />;
  }

  function renderMessageAndParent(entity, children): React.ReactElement {
    const entityDepth = entity?.depth;
    const shouldRenderMessage =
      entity &&
      entityDepth !== 0 &&
      // This check to render only immediate parent on page load
      // and load other parent's message when showParentMessagesUsed ref is true
      (showParentMessagesUsed.current
        ? true
        : entityDepth === deepLinkedMessage.depth || entityDepth === deepLinkedMessage.depth - 1);
    const decoratedMessageViewVariant: ItemViewTypeAndProps<ItemType.MESSAGE, MessageViewVariant> =
      {
        type: viewVariant.type,
        props: {
          ...viewVariant.props,
          useAccordionForReply: false,
          showNestedRepliesForDeepLinkedComment: entity?.uid === message?.uid,
          deepLinkMessageDepth: deepLinkedMessage?.depth,
          className: cx('lia-g-message-box', {
            'lia-message-focus': entity?.uid === message?.uid
          }),
          showEscalationBanner: message.board.conversationStyle === ConversationStyle.Forum
        }
      };
    return (
      <>
        {entity
          ? renderMessageAndParent(
              entity.parent,
              <div
                className={cx({
                  'lia-message': shouldRenderMessage,
                  'lia-message-scroll-margin': entity?.uid === message.uid
                })}
                ref={element => {
                  if (entity?.uid === message.uid) {
                    deepLinkedMessageRef.current = element;
                  }
                }}
              >
                {shouldRenderMessage && renderMessage(entity, decoratedMessageViewVariant)}
                {children}
              </div>
            )
          : children}
      </>
    );
  }

  function showParentMessages() {
    showParentMessagesUsed.current = true;
    fetchMore({
      variables: {
        id: lastParent.current?.id
      },
      updateQuery: (previousResult, { fetchMoreResult }): ContextMessageQuery => {
        const clonedDeepLinkedMessage = cloneDeep(deepLinkedMessage);

        let clonedParent = clonedDeepLinkedMessage;
        while (
          (clonedParent as ReplyMessage).parent &&
          (clonedParent as ReplyMessage).parent.depth > 1
        ) {
          clonedParent = (clonedParent as ReplyMessage).parent as Message;
        }
        (clonedParent as ReplyMessage).parent = (fetchMoreResult.message as ReplyMessage)
          .parent as Message;
        setDeepLinkedMessage(clonedDeepLinkedMessage);
        if (updatedMessageForDeepLink != null) {
          updatedMessageForDeepLinkVar(null);
        }
        deepLinkMessageVar(clonedDeepLinkedMessage);
        return null;
      }
    });
  }

  return (
    <>
      <div className={cx('lia-actions')}>
        <Button
          variant={ButtonVariant.LINK}
          className={cx('lia-g-loader-btn lia-g-mb-10')}
          onClick={viewFullDiscussion}
        >
          <Icon
            icon={Icons.ChevronDownIcon}
            className={cx('lia-g-mr-5')}
            color={IconColor.LOAD_TEXT}
            size={IconSize.PX_16}
          />
          {formatMessage('viewFullDiscussion', {
            repliesCount: rootMessage.repliesCount + newRepliesAdded.current
          })}
        </Button>
        {showParentMessagesAction && (
          <Button
            variant={ButtonVariant.LINK}
            className={cx('lia-g-loader-btn lia-g-mb-10')}
            onClick={showParentMessages}
          >
            {formatMessage('showParents')}
          </Button>
        )}
      </div>
      {renderMessageAndParent(deepLinkedMessage, null)}
    </>
  );
};

export default MessageDeepLink;
