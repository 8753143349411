import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import { IconColor, IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import Transition, {
  TransitionVariant
} from '@aurora/shared-client/components/common/Transition/Transition';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useMessagePolicies from '@aurora/shared-client/components/messages/useMessagePolicies';
import UserAvatar from '@aurora/shared-client/components/users/UserAvatar/UserAvatar';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import Icons from '@aurora/shared-client/icons';
import type { MessagePagesAndParams } from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import type { Article } from '@aurora/shared-generated/types/graphql-schema-types';
import {
  ContentWorkflowState,
  PostMessageType
} from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import IdConverter from '@aurora/shared-utils/graphql/IdConverter/IdConverter';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import UrlHelper from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlHelper';
import dynamic from 'next/dynamic';
import React, { useContext, useState } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import isEqual from 'react-fast-compare';
import { SwitchTransition } from 'react-transition-group';
import ConversationStyleBehaviorHelper from '../../../helpers/boards/ConversationStyleBehaviorHelper';
import type { MessageEditingState } from '@aurora/shared-client/helpers/ui/GlobalState';
import useGlobalState, {
  EditorLocation,
  GlobalStateType
} from '@aurora/shared-client/helpers/ui/GlobalState';
import type {
  MessageReplyCallToActionFragment,
  MessageViewFragment
} from '../../../types/graphql-types';
import { UrlObject, useContextObjectRefFromUrl } from '../../context/useContextObjectFromUrl';
import useTranslation from '../../useTranslation';
import localStyles from './MessageReplyCallToAction.module.pcss';
import { useIsPreviewMode } from '../useCurrentOrPreviewMessage';
import EmailVerificationContext from '../../context/EmailVerificationContext/EmailVerificationContext';

const MessageEditorInline = dynamic(() => import('../MessageEditorInline/MessageEditorInline'), {
  ssr: false
});

interface Props {
  /**
   * The message to reply to.
   */
  message: MessageReplyCallToActionFragment;
  /**
   * The class name.
   */
  className?: string;
}

/**
 * Displays a call-to-action for replying to a message. The call-to-action
 * appears as a faux input which is actually a button. When clicked, the button
 * is replaced by the `MessageEditorInline` and the cursor is focused in the RTE.
 * The component will not render unless the user has the ability to reply to
 * the specified message.
 *
 * @author Stephen McLaughry, Adam Ayres, Willi Hyde
 */
const MessageReplyCallToAction: React.FC<React.PropsWithChildren<Props>> = ({
  message,
  className
}) => {
  const cx = useClassNameMapper(localStyles);
  const tenant = useContext(TenantContext);
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_REPLY_CALL_TO_ACTION
  );
  const { authUser } = useContext(AppContext);
  const { addUpdateEmailToast } = useContext(EmailVerificationContext);
  const [messageEditingState, setMessageEditingState] = useGlobalState(
    GlobalStateType.MESSAGE_EDITING_STATE
  );
  const { id: replyId } = useContextObjectRefFromUrl(UrlObject.REPLY);
  const { confirmEmailStatus } = useRegistrationStatus();
  const postMessageType = PostMessageType.Reply;
  const editorLocation = EditorLocation.CTA;
  const { router } = useEndUserRoutes();
  const [count, increaseCount] = useState(0);
  const infoCount: Number = 42;
  const infoEnabled = count === infoCount;
  const { isAnonymous } = useRegistrationStatus();
  const isPreviewMode = useIsPreviewMode();
  const isMessagePreviewAndDraft =
    isPreviewMode && (message as Article)?.contentWorkflow?.state !== ContentWorkflowState.Publish;
  const { data: policiesData, loading: policiesLoading } = useMessagePolicies(
    module,
    {
      id: message.id,
      useCanReply: true
    },
    isAnonymous || IdConverter.isOptimistic(tenant, message?.id)
  );

  const canReply =
    checkPolicy(policiesData?.message?.messagePolicies?.canReply) && !isMessagePreviewAndDraft;

  if (textLoading || !canReply || policiesLoading) {
    return null;
  }

  const localEditingState: MessageEditingState = {
    messageId: message.id,
    postMessageType,
    editorLocation
  };

  const isReplying = isEqual(localEditingState, messageEditingState);

  /**
   * Function to navigate to full discussion page i.e. the message view page.
   * This gets triggered whenever we click on the ReplyCTA button in permalink page.
   */
  async function viewFullDiscussion(): Promise<void> {
    const { messagePage } = ConversationStyleBehaviorHelper.getInstance(message.board);

    await router.pushRoute<MessagePagesAndParams>(messagePage, {
      boardId: message.board.displayId,
      messageSubject: UrlHelper.determineSlugForMessagePath(message),
      messageId: message.uid.toString()
    });
  }

  function openEditor(): void {
    if (!confirmEmailStatus) {
      addUpdateEmailToast();
    } else {
      setMessageEditingState(localEditingState);
      if (replyId !== null) {
        viewFullDiscussion();
      }
    }
  }

  function checkCount(): void {
    if (count === infoCount) {
      return;
    }
    increaseCount(count + 1);
  }

  return !canReply ? (
    <div className={cx(className)}>
      <Button
        className={cx('lia-reply lia-is-read-only')}
        variant={ButtonVariant.NO_VARIANT}
        onClick={checkCount}
        aria-disabled="true"
      >
        <Icon
          icon={Icons.LockIcon}
          size={IconSize.PX_16}
          color={IconColor.GRAY_600}
          className={cx('lia-g-mr-10')}
        />
        <span className={cx('lia-reply-cta')}>
          {formatMessage(
            !infoEnabled ? `repliesTurnedOff.${message.board.conversationStyle}` : 'infoText'
          )}
        </span>
      </Button>
    </div>
  ) : (
    <>
      <SwitchTransition>
        <Transition variant={TransitionVariant.ZOOM_FADE} key={`${isReplying}`}>
          {!isReplying ? (
            <div className={cx(className)}>
              <Button
                className={cx('lia-reply')}
                variant={ButtonVariant.NO_VARIANT}
                onClick={openEditor}
                data-testid="MessageReplyCallToAction.Button"
              >
                <UserAvatar className={cx('lia-g-mr-10')} user={authUser} size={IconSize.PX_40} />
                <span className={cx('lia-reply-cta')}>{formatMessage('leaveReply')}</span>
              </Button>
            </div>
          ) : (
            <MessageEditorInline
              message={message as MessageViewFragment}
              postMessageType={postMessageType}
              wrapperClassName={cx('lia-form-wrapper', className)}
            />
          )}
        </Transition>
      </SwitchTransition>
    </>
  );
};

export default MessageReplyCallToAction;
